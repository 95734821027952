import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {Utils} from "../../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {EnumEnum} from "../../common/enums";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {Zmluva} from "../../model/klient/zmluva.entity";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XInputFile} from "./XInputFile";
import {CenovaKategoriaCena} from "../../model/klient/cenova-kategoria-cena.entity";
import {dateAsUI, dateFromModel, numberAsUI, numberFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {InputTextarea} from "primereact/inputtextarea";
import {CenovaKategoria} from "../../model/klient/cenova-kategoria.entity";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XFile} from "../../model/user/x-file.entity";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {KlientSluzbaAutoComplete} from "./KlientSluzbaAutoComplete";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {UlozitAVytvoritZmluvuRequest} from "../../common/hrom-evid-api";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

@Form("Zmluva")
export class ZmluvaForm extends XFormBaseModif {

    private zmluvaXFileFromEditStart: XFile | null = null;

    private klientSluzbaError: string | undefined;

    constructor(props: any) {
        super(props);

        // najoinujeme zaznam KlientSluzba, Klient a aj pohlavie, obec a stat - atributy na tychto zaznamoch su zobrazene v autocomplete na vyber klienta
        this.addField("klientSluzba.klient.pohlavie.name");
        this.addField("klientSluzba.klient.obec.nazov");
        this.addField("klientSluzba.klient.stat.nazov");
        // najoinujeme aj klientSluzba.klientSluzbaZakazList - pouziva sa na vycervenenie klienta v autocomplete
        this.addField("klientSluzba.klientSluzbaZakazList.id");

        // zobrazujeme aj jednotlive ceny cenovej kategorie
        this.addField("cenovaKategoria.cenovaKategoriaCenaList.cena");

        this.readOnlyKlientSluzba = this.readOnlyKlientSluzba.bind(this);
        this.onClickUlozitAVytvoritZmluvu = this.onClickUlozitAVytvoritZmluvu.bind(this);
    }

    // pomocna metodka
    private getZmluva(): Zmluva | null {
        return this.state.object;
    }

    createNewObject(): XObject {
        return {klientSluzba: null, predlzovanaZmluva: null, zmluvaXFile: null, version: 0};
    }

    preInitForm(zmluva: Zmluva, operationType: OperationType.Insert | OperationType.Update) {
        // ulozime si hodnotu fieldu zo zaciatku editacie
        this.zmluvaXFileFromEditStart = zmluva.zmluvaXFile;
    }

    readOnlyKlientSluzba(): boolean {
        const zmluva: Zmluva | null = this.getZmluva();
        if (zmluva === null) {
            return true;
        }

        // pri predlzovanej zmluve bude klient readOnly
        return zmluva.predlzovanaZmluva !== null || this.readOnlyAkMameZmluvu(zmluva);
    }

    readOnlyAkMameZmluvu(zmluva: Zmluva): boolean {
        // idealne vsetky atributy ktore sa pouziju do zmluvy by mali byt readOnly po vytvoreni zmluvy (aby nam nenastal rozdiel v hodnotach)
        return zmluva.zmluvaXFile !== null;
    }

    createCenaList(): string {
        let cenaList: string = "";
        const cenovaKategoria: CenovaKategoria | null | undefined = this.getZmluva()?.cenovaKategoria;
        if (cenovaKategoria) {
            // vyfiltrujeme ceny spadajuce do obdobia zmluvy
            let ckCenaList: CenovaKategoriaCena[] = cenovaKategoria.cenovaKategoriaCenaList;
            const datumOd: Date | null = dateFromModel(this.getZmluva()?.datumOd);
            if (datumOd !== null) {
                ckCenaList = ckCenaList.filter((ckCena: CenovaKategoriaCena) => dateFromModel(ckCena.datumDo)! >= datumOd);
            }
            const datumDo: Date | null = dateFromModel(this.getZmluva()?.datumDo);
            if (datumDo !== null) {
                ckCenaList = ckCenaList.filter((ckCena: CenovaKategoriaCena) => dateFromModel(ckCena.datumOd)! <= datumDo);
            }
            for (const ckCena of ckCenaList) {
                if (cenaList !== "") {
                    cenaList += XUtilsCommon.newLine;
                }
                cenaList += `${dateAsUI(dateFromModel(ckCena.datumOd))} - ${dateAsUI(dateFromModel(ckCena.datumDo))}: ${numberAsUI(numberFromModel(ckCena.cena))}`;
            }
        }
        return cenaList;
    }

    async validate(zmluva: Zmluva): Promise<XErrors> {
        const errors: XErrors = {};

        if (this.klientSluzbaError) {
            errors.klientSluzba = this.klientSluzbaError;
        }
        else if (!zmluva.klientSluzba) {
            errors.klientSluzba = "Klient musí byť vyplnený.";
        }

        const datumOd: Date = dateFromModel(zmluva.datumOd)!; // povinny
        const datumDo: Date | null = dateFromModel(zmluva.datumDo);
        if (datumDo !== null && datumDo < datumOd) {
            const errorMsg: string = `Dátum do musí byť väčší alebo rovný ako Dátum od`;
            errors.datumOd = errorMsg;
            errors.datumDo = errorMsg;
        }
        else {
            // datumOd/datumDo sa nesmu prekryvat s inou zmluvou/predlzenim
            if (zmluva.klientSluzba) {
                const zmluvaList: Zmluva[] = await XUtils.fetchRows('Zmluva',
                    {
                        where: `[klientSluzba] = :klientSluzbaId AND [id] <> :id AND [datumOd] <= ${XUtilsCommon.sqlMaxDateIfNull(":datumDo")} AND ${XUtilsCommon.sqlMaxDateIfNull("[datumDo]")} >= :datumOd`,
                        params: {klientSluzbaId: zmluva.klientSluzba.id, id: zmluva.id ?? 0, datumOd: datumOd, datumDo: datumDo}
                    });
                if (zmluvaList.length > 0) {
                    const errorMsg: string = `Zadané obdobie Dátum od - Dátum do sa prekrýva s obdobím na zmluve/predĺžení id = ${zmluvaList[0].id}`;
                    errors.datumOd = errorMsg;
                    errors.datumDo = errorMsg;
                }
            }
        }

        return errors;
    }

    // async onClickUlozitAVytvoritZmluvu() {
    //     try {
    //         await XUtils.fetch('ulozit-a-vytvorit-zmluvu', {});
    //         alert("Zmluva bola vytlacena");
    //     }
    //     catch (e) {
    //         XUtils.showErrorMessage('Nepodarilo sa vytlacit.', e);
    //     }
    // }

    async onClickUlozitAVytvoritZmluvu() {

        if (!this.validateSave()) {
            return;
        }

        this.preSave(this.state.object);

        try {
            const ulozitAVytvoritZmluvuRequest: UlozitAVytvoritZmluvuRequest = {zmluva: this.state.object, sluzba: Utils.getCurrentSluzba()!};
            await XUtils.fetch('ulozit-a-vytvorit-zmluvu', ulozitAVytvoritZmluvuRequest);
        }
        catch (e) {
            XUtils.showErrorMessage("Nepodarilo sa uložiť/vytvoriť zmluvu.", e);
            return; // zostavame vo formulari
        }

        (this.props as any).openForm(null); // standardny rezim; save zbehol, ideme naspet do browsu
    }


    render() {
        return (
            <div>
                <XFormHeader form={this} label="Zmluva"/>
                <XFormRow>
                    <XFormCol labelStyle={{width:'16rem'}}>
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modifikoval" size={20}/>
                        <XInputText form={this} field="cisloZmluvy" label="Číslo zmluvy/dodatku" size={10} readOnly={this.readOnlyAkMameZmluvu}/>
                        <XInputText form={this} field="predlzovanaZmluva.cisloZmluvy" label="Číslo predlžovanej zmluvy" size={10}/>
                    </XFormCol>
                    <XFormCol labelStyle={{width:'12rem'}}>
                        <div className="field grid">
                            <label htmlFor="klient" className="col-fixed" style={{width:'12rem'}}>Klient *</label>
                            <KlientSluzbaAutoComplete value={this.state.object ? this.state.object.klientSluzba : null}
                                                      onChange={(object: KlientSluzba | null, objectChange: OperationType) => {this.state.object.klientSluzba = object; this.setStateXForm();}}
                                                      readOnly={this.readOnlyKlientSluzba()} maxWidth="17rem"
                                                      error={Utils.getError(this.state.errorMap, "klientSluzba")}
                                                      onErrorChange={(error: (string | undefined)) => this.klientSluzbaError = error}/>
                        </div>
                        <XInputDate form={this} field="datumOd" label="Dátum od" readOnly={this.readOnlyAkMameZmluvu}/>
                        <XInputDate form={this} field="datumDo" label="Dátum do" readOnly={this.readOnlyAkMameZmluvu}/>
                        <XAutoComplete form={this} assocField="cenovaKategoria" displayField="nazov" label="Cenová kategória"
                                       filter={{where: "[sluzba] = :sluzbaId", params: {"sluzbaId": Utils.getCurrentSluzbaId() ?? 0}}} fields={["cenovaKategoriaCenaList.cena"]}
                                       readOnly={this.readOnlyAkMameZmluvu} width="17rem"/>
                        <div className="field grid">
                            <label className="col-fixed" style={{width:'12rem'}}>Cena</label>
                            {/* dame zatial 3 riadky natvrdo, to by malo vzdy stacit */}
                            <InputTextarea value={this.createCenaList()} readOnly={true} rows={3} style={{width: '17rem'}}/>
                        </div>
                        <XInputDate form={this} field="datumPodpisu" label="Dátum podpisu"/>
                        <XInputFile form={this} fileField="zmluvaXFile" label="Zmluva" subdir="zmluvy" downloadAndRemoveOnly={true}/>
                    </XFormCol>
                    <XFormCol labelStyle={{width:'17.5rem'}}>
                        <XDropdownEnum form={this} assocField="druhStravy" label="Druh stravy" enumEnumCode={EnumEnum.druhStravy}/>
                        <XInputText form={this} field="inaStrava" label="Iná strava" size={30}/>
                        <XDropdownEnum form={this} assocField="sposobUkoncenia" label="Spôsob ukončenia" enumEnumCode={EnumEnum.sposobUkoncenia}/>
                        <XInputDate form={this} field="datumPodpisuUkoncenia" label="Dátum podpisu ukončenia"/>
                        <XInputDecimal form={this} field="vypovednaDoba" label="Výpovedná doba" size={4}/>
                        <XInputDate form={this} field="datumDoruceniaUkoncenia" label="Dátum doručenia ukončenia"/>
                        <XInputTextarea form={this} field="poznamkaKUkonceniu" label="Poznámka k ukončeniu" labelOnTop cols="full" autoResize={true}/>
                    </XFormCol>
                </XFormRow>
                <div className="flex justify-content-center">
                    <XButton icon="pi pi-save" label={xLocaleOption('save')} onClick={this.onClickSave}/>
                    {this.zmluvaXFileFromEditStart === null ? <XButton icon="pi pi-save" label="Uložiť a vytvoriť zmluvu" onClick={this.onClickUlozitAVytvoritZmluvu}/> : null}
                    <XButton icon="pi pi-times" label={xLocaleOption('cancel')} onClick={this.onClickCancel}/>
                </div>
            </div>
        );
    }
}
