import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {Klient} from "../../model/klient/klient.entity";
import {OperationType} from "@michalrakus/x-react-web-lib/XUtils";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {Utils} from "../../Utils";
import {KlientBrowse} from "./KlientBrowse";
import {KlientForm} from "./KlientForm";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

// ak user stlaci ok, vrati dvojicku (klient, objectChange) - vysledok AutoComplete-u

export interface HladatKlientaMimoSluzbuDialogState {
    opened: boolean;
    // datumOd?: Date | null;
    // datumDo?: Date | null;
    onHideDialog?: (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => void;
}

export const HladatKlientaMimoSluzbuDialog = (props: {
    dialogState: HladatKlientaMimoSluzbuDialogState;
}) => {

    const [klient, setKlient] = useState<Klient | null>(null);
    // const [datumDo, setDatumDo] = useState<Date | null>(null);

    let klientError: string | undefined = undefined;

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        setKlient(null);
        //setDatumDo(props.dialogState.datumDo ?? null);
    }

    const onPouzitKlienta = () => {

        if (klientError) {
            alert(klientError);
            return;
        }

        if (!klient) {
            alert("Klient musí byť zadaný.");
            return;
        }

        props.dialogState.onHideDialog!(true, klient, OperationType.None);
    }

    const onVytvoritKlienta = () => {

        props.dialogState.onHideDialog!(true, null, OperationType.None);
    }

    const onHide = () => {
        // onHide sa zavola aj ked zbehnu metody onPouzitKlienta/onVytvoritKlienta a vtedy uz nemame props.dialogState.onHideDialog
        if (props.dialogState.onHideDialog) {
            props.dialogState.onHideDialog(false);
        }
    }

    return (
        <Dialog visible={props.dialogState.opened} position="top" onShow={onShow} onHide={onHide}>
            <XFormHeader label={`Klienti z ostatných služieb (${Utils.isSluzbaNoclaharen() ? "ošetrovňa" : "nocľaháreň"}, útulky, ...)`}/>
            <div className="field grid">
                <label className="col-fixed" style={{width:'7rem'}}>Klient</label>
                <XAutoCompleteBase value={klient} onChange={(klient: Klient | null, objectChange: OperationType) => setKlient(klient)}
                                   onErrorChange={(error: string | undefined) => klientError = error} idField="id"
                                   field={["meno", "priezvisko", "prezyvka", "pohlavie.name", "datumNarodenia", "ulica", "cisloDomu", "psc", "obecText", "obec.nazov", "stat.nazov", "ID:id"]}
                                   suggestionsQuery={{entity: "Klient", filter: Utils.klientFilterCurrentSluzba(true)}}
                                   searchBrowse={<KlientBrowse label="Klienti z ostatných služieb"/>} width="63rem" scrollHeight="50rem"
                                   suggestionsLoad="lazy" lazyLoadMaxRows={15} minLength={3}/>
            </div>
            <div className="flex justify-content-center">
                <XButton key="pouzit-najdeneho" label="Použiť nájdeného klienta" onClick={onPouzitKlienta}/>
                <XButton key="vytvorit" label="Vytvoriť nového klienta" onClick={onVytvoritKlienta}/>
            </div>
        </Dialog>
    );
}
