import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";

// dialog vytvoreny podla XDateFromToDialog, ma navyse atribut yearForAge,
// dialog je pouzivany pre funkciu Run statistic,

export interface XRunStatisticDialogState {
    opened: boolean;
    showDateFromDateTo?: boolean;
    dateFrom?: Date | null;
    dateTo?: Date | null;
    showYearForAge?: boolean;
    yearForAge?: Date | null;
    onHideDialog?: (ok: boolean, dateFrom: Date | null, dateTo: Date | null, yearForAge: Date | null) => void;
}

export const XRunStatisticDialog = (props: {
    dialogState: XRunStatisticDialogState;
}) => {

    const [dateFrom, setDateFrom] = useState<Date | null>(null);
    const [dateTo, setDateTo] = useState<Date | null>(null);
    const [yearForAge, setYearForAge] = useState<Date | null>(null);

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        setDateFrom(props.dialogState.dateFrom ?? null);
        setDateTo(props.dialogState.dateTo ?? null);
        setYearForAge(props.dialogState.yearForAge ?? null);
    }

    const onHide = () => {
        if (props.dialogState.onHideDialog) {
            props.dialogState.onHideDialog(false, null, null, null);
        }
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogState.opened} onShow={onShow} onHide={onHide}>
            {props.dialogState.showDateFromDateTo ?
                [<div className="field grid">
                    <label className="col-fixed" style={{width: '12rem'}}>{xLocaleOption('dateFrom')}</label>
                    <XCalendar value={dateFrom} onChange={(value: Date | null) => setDateFrom(value)}/>
                </div>,
                <div className="field grid">
                    <label className="col-fixed" style={{width: '12rem'}}>{xLocaleOption('dateTo')}</label>
                    <XCalendar value={dateTo} onChange={(value: Date | null) => setDateTo(value)}/>
                </div>]
                : null
            }
            {props.dialogState.showYearForAge ?
                <div className="field grid">
                    <label className="col-fixed" style={{width: '12rem'}}>{xLocaleOption('yearForAgeCalculation')} *</label>
                    <XCalendar value={yearForAge} onChange={(value: Date | null) => setYearForAge(value)} scale={XDateScale.Year}/>
                </div>
                : null
            }
            <div className="flex justify-content-center">
                <XButton label={xLocaleOption('ok')} onClick={() => props.dialogState.onHideDialog!(true, dateFrom, dateTo, yearForAge)}/>
            </div>
        </Dialog>
    );
}
