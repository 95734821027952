import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {EnumEnum, XsStatFieldType} from "../../common/enums";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XsStatField} from "../../model/statistics/xs-stat-field.entity";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {XFieldSetDialog, XFieldSetDialogState} from "./XFieldSetDialog";
import {XButtonIconMedium} from "@michalrakus/x-react-web-lib/XButtonIconMedium";
import {XFieldSetValues} from "../zapisy/XFieldSetBase";
import {XFieldSetMeta} from "../../model/zapisy/x-field-set-meta.entity";
import {InputText} from "primereact/inputtext";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XFormInlineRow} from "@michalrakus/x-react-web-lib/XFormInlineRow";
import {Utils, XsAccessType} from "../../Utils";

@Form("XsStatField")
export class XsStatFieldForm extends XFormBaseModif {

    constructor(props: any) {
        super(props);

        const xFieldSetDialogState: XFieldSetDialogState = {
            opened: false
        }
        this.state.xFieldSetDialogState = xFieldSetDialogState;

        // join aggregateFunction/xFieldSetMeta because its component is not present during creating component XsStatFieldForm
        this.addField("aggregateFunction.name");
        this.addField("xFieldSetMeta.fieldSetId");

        this.editFieldSetFields = this.editFieldSetFields.bind(this);
        this.editFieldSetFieldsOnHideDialog = this.editFieldSetFieldsOnHideDialog.bind(this);
    }

    createNewObject(): XObject {
        return {yearForAgeFromDialog: false, version: 0};
    }

    // pomocna metodka
    private getXsStatField(): XsStatField | null {
        return this.state.object;
    }

    formReadOnly(object: XObject, field: string): boolean {
        return !Utils.xsUserHasAccess(XsAccessType.full);
    }

    private isFieldType(xsStatFieldType: XsStatFieldType): boolean {
        return this.getXsStatField()?.fieldType?.code === xsStatFieldType;
    }

    private editFieldSetFields() {
        const xFieldSetMeta: XFieldSetMeta | null | undefined = this.getXsStatField()?.xFieldSetMeta;
        if (!xFieldSetMeta) {
            alert('Field "Field set *" is required');
            return;
        }

        const xsStatField: XsStatField = this.getXsStatField()!;
        if (!xsStatField.xFieldSetFields) {
            xsStatField.xFieldSetFields = {};
        }

        const xFieldSetDialogState: XFieldSetDialogState = {
            opened: true,
            fieldSetId: xFieldSetMeta.fieldSetId,
            fieldSetValues: xsStatField.xFieldSetFields as XFieldSetValues,
            onHideDialog: this.editFieldSetFieldsOnHideDialog
        }
        this.setState({xFieldSetDialogState: xFieldSetDialogState});
    }

    private editFieldSetFieldsOnHideDialog(ok: boolean, fieldSetValues: XFieldSetValues | undefined) {
        if (ok) {
            // zapiseme do modelu
            this.getXsStatField()!.xFieldSetFields = fieldSetValues!;
            // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
            this.setStateXForm();
        }

        const xFieldSetDialogState: XFieldSetDialogState = {
            opened: false
        }
        this.setState({xFieldSetDialogState: xFieldSetDialogState});
    }

    async validate(object: XsStatField): Promise<XErrors> {
        const errors: XErrors = {};
        if (this.isFieldType(XsStatFieldType.aggregateField) || this.isFieldType(XsStatFieldType.fieldSetAggregateField)) {
            if (!object.aggregateFunction) {
                errors.aggregateType = "Field is required.";
            }
            if (!object.field) {
                errors.field = "Field is required.";
            }
        }
        if (this.isFieldType(XsStatFieldType.fieldSetRowCount) || this.isFieldType(XsStatFieldType.fieldSetFieldCount)) {
            if (!object.field) {
                errors.field = "Field is required.";
            }
            if (!object.xFieldSetMeta) {
                errors.xFieldSetMeta = "Field is required.";
            }
            console.log("test");
            console.log(object.xFieldSetFields);
            if (!object.xFieldSetFields || XUtilsCommon.objectAsJSON(object.xFieldSetFields) === "{}") { // ak nie je ziadna klikacka vykliknuta
                errors.xFieldSetFields = "Field is required.";
            }
        }
        return errors;
    }

    render() {
        const labelStyle: React.CSSProperties = {width:'11rem'};

        return (
            <div>
                <XFormHeader form={this} label="Statistic field"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" inputStyle={{width:'12.5rem'}}/>
                        <XInputText form={this} field="label" label="Label" inputStyle={{width:'20rem'}}/>
                        <XInputText form={this} field="entity" label="Entity" inputStyle={{width:'15rem'}}/>
                        <XInputText form={this} field="filter" label="Filter" inputStyle={{width:'30rem'}}/>
                        <XInputText form={this} field="dateField" label="Date field" inputStyle={{width:'30rem'}}/>
                        <XCheckbox form={this} field="yearForAgeFromDialog" label="Year for age from dialog" labelStyle={{width:'15rem'}}/>
                        <XDropdownEnum form={this} assocField="fieldType" label="Field type" enumEnumCode={EnumEnum.xsStatFieldType}/>
                        {this.isFieldType(XsStatFieldType.aggregateField) || this.isFieldType(XsStatFieldType.fieldSetAggregateField) ?
                            [<XDropdownEnum form={this} assocField="aggregateFunction" label="Aggregate function *" labelStyle={labelStyle} enumEnumCode={EnumEnum.xAggregateFunction}/>,
                            <XInputText form={this} field="field" label="Field *" inputStyle={{width:'15rem'}}/>] : null}
                        {this.isFieldType(XsStatFieldType.fieldSetRowCount) || this.isFieldType(XsStatFieldType.fieldSetFieldCount) ?
                            [<XInputText form={this} field="field" label="Json field *" inputStyle={{width:'15rem'}}/>,
                            <XAutoComplete form={this} assocField="xFieldSetMeta" displayField="fieldSetId" label="Field set *"/>,
                            <div className="field grid">
                                <label htmlFor="xFieldSetFields" className="col-fixed" style={{width:'10.5rem'}}>Field set fields *</label>
                                <InputText id="xFieldSetFields" value={XUtilsCommon.objectAsJSON(this.state.object.xFieldSetFields)} readOnly={true} style={{width:'30rem'}}/>
                                <XButtonIconMedium icon="pi pi-pencil" onClick={this.editFieldSetFields}/>
                            </div>,
                            <XFieldSetDialog dialogState={this.state.xFieldSetDialogState}/>] : null}
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
