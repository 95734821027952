import React from "react";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {EnumEnum} from "../../common/enums";

// komponent ktory zobrazi jednu cinnost v tab-ke Hodnotenie samostatnosti
export const KlientSluzbaSamostCinnost = (props: {
    form: XFormBase; // this
    label: string;
    field: string;
    popis2: string;
    popis1: string;
}) => {

    return (
        <div className="mb-4">
            <XDropdownEnum form={props.form} assocField={props.field} label={props.label} labelStyle={{width: '17rem', fontWeight: 'bold'}} enumEnumCode={EnumEnum.samostatnost}/>
            <div>{props.popis2}</div>
            <div>{props.popis1}</div>
        </div>
    );
}
