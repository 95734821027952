import React from "react";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {ObecBrowse} from "../ciselniky/ObecBrowse";
import {ObecForm} from "../ciselniky/ObecForm";
import {StatBrowse} from "../ciselniky/StatBrowse";
import {StatForm} from "../ciselniky/StatForm";
import {Obec} from "../../model/ciselniky/obec.entity";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {Klient} from "../../model/klient/klient.entity";
import {statSlovensko} from "../../common/constants";

// ZOMBIE subor po migracii noclaharne na KlientSluzba
// komponent ktory zobrazi polozky adresy daneho klienta
// verzia pre noclaharen (root zaznam je Klient), inac je to analogia ku KlientSluzbaAdresa (osetrovna = false)
export const KlientAdresa = (props: {
    form: XFormBase; // this
    osetrovna: boolean; // osetrovna nezobrazuje/nepouziva ulica, cisloDomu, psc
    labelStyle?: React.CSSProperties;
}) => {

    // pomocna metodka
    const getKlient = (): Klient | null => {
        return props.form.state.object;
    }

    // helper
    const mameZahranicie = (klient: Klient): boolean => {
        // mame ciselnik obec zadavame, len ak je zadany nejaky stat a ten stat nie je Slovensko
        return (klient && klient.stat && klient.stat.id !== statSlovensko.id) as boolean;
    }

    const onChangeKlientStat = (e: XFieldChangeEvent<Klient>) => {
        // ak bol stat zmeneny na zahranicie a mame vyplneny ciselnik obec, tak prehodime obec do textoveho fieldu obecText - dalej nech si s tym user robi co uzna za vhodne (napr. vymaze)
        // dovod je ze nechceme mat nekonzistentny stav, kedy mame zahranicie a zaroven mame vyplnenu slovensku obec (v readOnly fielde obec)
        // opacny postup (presun obecText -> obec) nerealizujeme, lebo nevieme najst obec v ciselniku (mame aj kopec dat z migracie kde nie je mozne najst obec)
        if (mameZahranicie(e.object)) {
            if (e.object.obec !== null) {
                // ak je obecText prazdne (co by malo byt vzdy), tak don zapiseme nazov obce
                if (e.object.obecText === null || e.object.obecText === '') {
                    e.object.obecText = e.object.obec.nazov;
                }
                // zrusime obec
                e.object.obec = null;
            }
        }
    }

    const readOnlyKlientObecText = (klient: Klient): boolean => {
        // obecText pouzivame len pre zahranicie (a docasne po migracii)
        return !mameZahranicie(klient);
    }

    const onChangeKlientObecText = (e: XFieldChangeEvent<Klient>) => {
        // ak user zadal obec do textoveho fieldu obecText, tak zrusime ciselnikovu hodnotu (obec), ak nejaku mame
        if (e.object.obecText !== null) {
            e.object.obec = null;
        }
    }

    const readOnlyKlientObec = (klient: Klient): boolean => {
        // ciselnik obec zadavame, len ak nemame zahranicie
        return mameZahranicie(klient);
    }

    const itemTemplateKlientObec = (suggestion: Obec, index: number, createStringValue: boolean, defaultValue: (suggestion: Obec) => string): React.ReactNode => {
        let obec: string = suggestion.nazov;
        if (suggestion.okres) {
            obec += ", okres " + suggestion.okres.nazov;
        }
        return obec;
    }

    const onChangeKlientObec = (e: XFieldChangeEvent<Klient>) => {
        // ak user zadal obec z ciselnika (obec), tak zrusime hodnotu v textovom fielde obecText, ak nejaku mame
        if (e.object.obec !== null) {
            e.object.obecText = null;
        }
    }

    const klient: Klient | null = getKlient();

    return (<React.Fragment>
        {/* stat sme presunuli na zaciatok, lebo ak je vyplneny nejaky zahranicny stat (nie Slovensko), tak sa pouziva obecText, pre Slovensko sa pouziva ciselnik obci */}
        <XAutoComplete form={props.form} assocField="stat" displayField="nazov" label="Štát" labelStyle={props.labelStyle} width="21rem"
                       readOnly={false} onChange={onChangeKlientStat}
                       searchBrowse={<StatBrowse/>} assocForm={<StatForm/>}/>
        {/* mesto - nahradzame ciselnikom obci, docasne zobrazujeme ako readOnly (a v osetrovni nepouzivame vobec) */}
        {!props.osetrovna ?
            [<XInputText form={props.form} field="ulica" label="Ulica" labelStyle={props.labelStyle} inputStyle={{width: '13rem'}} readOnly={false}/>,
            <XInputText form={props.form} field="cisloDomu" label="Číslo domu" labelStyle={props.labelStyle} inputStyle={{width: '7rem'}} readOnly={false}/>,
            <XInputText form={props.form} field="psc" label="PSČ" labelStyle={props.labelStyle} inputStyle={{width: '5rem'}} readOnly={false}/>]
            : null
        }
        {/* poznamka: pre usporu miesta (a jednoduchsi zivot uzivatela) zobrazujeme obecText + obec ako readOnly len ak maju zapisanu nejaku hodnotu */}
        {klient && (!readOnlyKlientObecText(klient) || klient.obecText !== null) ?
            <XInputText form={props.form} field="obecText" label="Mesto/obec" labelStyle={props.labelStyle} inputStyle={{width:'21rem'}}
                        readOnly={readOnlyKlientObecText} onChange={onChangeKlientObecText}/> : null}
        {/* poznamka: koli tomu aby fungovalo vyhladavanie okresnych miest (napr. Trencin, Piestany) nedavame do displayField "okres.nazov" - potom by pri natypovani Trencin zobrazil vsetky obce Trencin, co nechceme */}
        {/* poznamka 2: aby sme vedeli najst zaznamy Bratislava, resp. Kosice, pouzivame sortField="nazov" - inac by sortoval podla id a koli lazyLoadMaxRows=10 by natiahol ako prve mestske casti a zaznam Bratislava by sa nedostal do vyberu */}
        {klient && (!readOnlyKlientObec(klient) || klient.obec !== null) ?
            <XAutoComplete form={props.form} assocField="obec" label="Mesto/obec" labelStyle={props.labelStyle} readOnly={readOnlyKlientObec}
                           displayField={["nazov"]} fields={["okres.nazov"]} sortField="nazov" itemTemplate={itemTemplateKlientObec}
                           suggestionsLoad="lazy" minLength={2} onChange={onChangeKlientObec}
                           width="21rem" scrollHeight="50rem"
                           searchBrowse={<ObecBrowse/>} assocForm={<ObecForm/>}/> : null}
    </React.Fragment>);
}
