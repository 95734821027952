import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {
    XFormAutoCompleteColumn,
    XFormColumn,
    XFormDataTable2, XFormDropdownColumn
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {Utils, XsAccessType} from "../../Utils";
import {XsTableSubtable} from "../../model/statistics/xs-table-subtable.entity";
import {Dialog} from "primereact/dialog";
import {EnumEnum, XGroupByType, XrWhereItemType} from "../../common/enums";
import {XsGroupByFieldBrowse} from "../statistics/XsGroupByFieldBrowse";
import {XsGroupByFieldForm} from "../statistics/XsGroupByFieldForm";
import {XrReport} from "../../model/reports/xr-report.entity";
import {stringAsDB} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XrReportWhereItem} from "../../model/reports/xr-report-where-item.entity";
import {XEnum} from "../../model/user/x-enum.entity";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Button} from "primereact/button";
import {Ubytovanie} from "../../model/noclaharen/ubytovanie.entity";

@Form("XrReport")
export class XrReportForm extends XFormBaseModif {

    constructor(props: XFormProps) {
        super(props);

        this.state.showSqlQueryDialogState = {opened: false};

        this.createFilterForXsGroupByField = this.createFilterForXsGroupByField.bind(this);
        this.showSqlQuery = this.showSqlQuery.bind(this);
    }

    // pomocna metodka
    private getXrReport(): XrReport | null {
        return this.state.object;
    }

    createNewObject(): XObject {
        return {xrReportColumnList: [], xrReportWhereItemList: [], xrReportSortFieldList: [], version: 0};
    }

    formReadOnly(object: XObject, field: string): boolean {
        return !Utils.xsUserHasAccess(XsAccessType.usageOnly);
    }

    createFilterForXsGroupByField(object: XrReport, rowData: any): XCustomFilter | undefined {
        let filter: XCustomFilter | undefined = undefined;
        if (object) {
            // for now only for entityEnum and xEnum
            filter = {
                where: `[fieldType.code] IN (:...groupByTypeList) AND (EXISTS (SELECT 1 FROM ${Utils.getSchema()}.xs_group_by_field_by_entity gbe WHERE gbe.xs_group_by_field_id = [id] AND gbe.entity = ${stringAsDB(object.entity)}))`,
                params: {groupByTypeList: [XGroupByType.entityEnum, XGroupByType.xEnum]}
            };
        }
        return filter;
    }

    readOnlyXsGroupByField(object: any, tableRow: XrReportWhereItem): boolean {
        const itemType: XEnum = tableRow.itemType;
        return !(itemType && itemType.code === XrWhereItemType.groupByField);
    }

    readOnlyDateField(object: any, tableRow: XrReportWhereItem): boolean {
        const itemType: XEnum = tableRow.itemType;
        return !(itemType && itemType.code === XrWhereItemType.dateFromTo);
    }

    // pre groupByField tu moze byt napr. nejaka podmienka so subselect-om ktory vo vnutri vyuzije nejaky spec. param <id> ktory sa nahradi konkretnou hodnotou ktoru zadal user v dialogu (este to neni zimplementovane)
    // readOnlyWhereItem(object: any, tableRow: XrReportWhereItem): boolean {
    //     const itemType: XEnum = tableRow.itemType;
    //     return !(itemType && (itemType.code === XrWhereItemType.groupByField || itemType.code === XrWhereItemType.filter));
    // }

    showSqlQuery() {
        this.setState({showSqlQueryDialogState: {opened: true, sqlQuery: this.getXrReport()?.sqlQuery}});
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label="Statistic table"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" inputStyle={{width:'12.5rem'}}/>
                        <XInputText form={this} field="label" label="Label" inputStyle={{width:'20rem'}}/>
                        <XInputText form={this} field="entity" label="Entity" inputStyle={{width:'15rem'}}/>
                        <div className="field grid">
                            <label className="col-fixed" style={{width: XUtils.FIELD_LABEL_WIDTH}}>Sql</label>
                            <Button icon="pi pi-search" onClick={this.showSqlQuery}/>
                        </div>
                        <XFormDataTable2 form={this} assocField="xrReportColumnList" label="Columns" sortField="columnOrder">
                            <XFormColumn field="columnOrder" header="Order" width="4rem"/>
                            <XFormColumn field="header" header="Header" width="15rem"/>
                            <XFormColumn field="field" header="Field" width="50rem"/>
                            <XFormDropdownColumn assocField="fieldType" displayField="name" header="Field type" filter={Utils.enumFilter(EnumEnum.xFieldType)} sortField="enumOrder" width="7rem"/>
                            <XFormColumn field="width" header="Width" width="5rem"/>
                        </XFormDataTable2>
                        <XFormDataTable2 form={this} assocField="xrReportWhereItemList" label="Where items (filters)" sortField="itemOrder">
                            <XFormColumn field="itemOrder" header="Order" width="4rem"/>
                            <XFormDropdownColumn assocField="itemType" displayField="name" header="Item type" filter={Utils.enumFilter(EnumEnum.xrWhereItemType)} sortField="enumOrder" width="10rem"/>
                            <XFormAutoCompleteColumn assocField="xsGroupByField" displayField="label" header="Group by field" width="25rem" fields={["fieldType.name"]}
                                                     searchBrowse={<XsGroupByFieldBrowse/>} assocForm={<XsGroupByFieldForm/>} filter={this.createFilterForXsGroupByField}
                                                     readOnly={this.readOnlyXsGroupByField} addRowEnabled={Utils.xsUserHasAccess(XsAccessType.full)}/>
                            <XFormColumn field="label" header="Label" width="15rem"/>
                            <XFormColumn field="dateField" header="Date field" width="15rem" readOnly={this.readOnlyDateField}/>
                            <XFormColumn field="whereItem" header="Filter" width="15rem"/>
                        </XFormDataTable2>
                        <XFormDataTable2 form={this} assocField="xrReportSortFieldList" label="Sort fields" sortField="fieldOrder">
                            <XFormColumn field="fieldOrder" header="Order" width="4rem"/>
                            <XFormColumn field="field" header="Field" width="15rem"/>
                            <XFormDropdownColumn assocField="order" displayField="name" header="Order" filter={Utils.enumFilter(EnumEnum.xOrderType)} sortField="enumOrder" width="10rem"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
                <Dialog visible={this.state.showSqlQueryDialogState.opened} onHide={() => this.setState({showSqlQueryDialogState: {opened: false}})}>
                    <div>{this.state.showSqlQueryDialogState.sqlQuery}</div>
                </Dialog>
            </div>
        );
    }
}
