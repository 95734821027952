import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XsStatFieldForm} from "./XsStatFieldForm";
import {Utils, XsAccessType} from "../../Utils";

export const XsStatFieldBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsStatFieldForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsStatFieldForm id={selectedRow.id}/>);
    }

    const canAddRemoveRow = Utils.xsUserHasAccess(XsAccessType.full);

    return (
        <XLazyDataTable entity="XsStatField" label="Statistic fields" sortField="id desc" rows={30}
                        onAddRow={canAddRemoveRow ? onAddRow : undefined} onEdit={onEdit} removeRow={canAddRemoveRow}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="label" header="Label" width="20rem"/>
            <XLazyColumn field="entity" header="Entity" width="15rem"/>
            <XLazyColumn field="dateField" header="Date field" width="10rem"/>
            <XLazyColumn field="fieldType.name" header="Field type" width="15rem"/>
            <XLazyColumn field="aggregateFunction.name" header="Aggregate function" width="12rem"/>
            <XLazyColumn field="field" header="Field" width="15rem"/>
        </XLazyDataTable>
    );
}
