import {Sluzba} from "../model/user/sluzba.entity";
import {SluzbaEnum} from "./enums";
import {Stat} from "../model/ciselniky/stat.entity";

// konstanty hlavne pre inicializaciu asociacii, nechce sa mi to nacitavat z DB

export const sluzbaStreetwork: Sluzba = {id: 6, kod: SluzbaEnum.streetwork} as Sluzba;

export const sluzbaNoclaharen: Sluzba = {id: 4, kod: SluzbaEnum.noclaharen} as Sluzba;

export const statSlovensko: Stat = {id: 1, nazov: 'Slovensko'} as Stat;
