import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {
    XFormColumn,
    XFormDataTable2
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {XsIntervalSet} from "../../model/statistics/xs-interval-set.entity";
import {OperationType} from "@michalrakus/x-react-web-lib/XUtils";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XsIntervalItem} from "../../model/statistics/xs-interval-item.entity";
import {numberFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {Utils, XsAccessType} from "../../Utils";

@Form("XsIntervalSet")
export class XsIntervalSetForm extends XFormBaseModif {

    createNewObject(): XObject {
        return {xsIntervalItemList: [], useLabelAndLess: false, useLabelAndMore: false, version: 0};
    }

    preInitForm(xsIntervalSet: XsIntervalSet, operationType: OperationType.Insert | OperationType.Update) {
        // sortujeme tu lebo ak pouzijeme prop sortField na tabulke, tak sortuje v priebehu editacie
        // z backendu chodia stringy (napr. "10.34"), preto konvertujeme na numbers
        xsIntervalSet.xsIntervalItemList = XUtilsCommon.arraySort(xsIntervalSet.xsIntervalItemList, (xsIntervalItem: XsIntervalItem) => numberFromModel(xsIntervalItem.valueFrom));
    }

    formReadOnly(object: XObject, field: string): boolean {
        return !Utils.xsUserHasAccess(XsAccessType.full);
    }

    async validate(object: XsIntervalSet): Promise<XErrors> {
        const errors: XErrors = {};
        return errors;
    }

    render() {
        const labelStyle: React.CSSProperties = {width:'15rem'};

        return (
            <div>
                <XFormHeader form={this} label="Interval set"/>
                <div className="x-form-row">
                    <div className="x-form-col">
                        <XInputDecimal form={this} field="id" label="ID" labelStyle={labelStyle} readOnly={true}/>
                        <XInputDate form={this} field="modifDate" label="Modified at" labelStyle={labelStyle} readOnly={true}/>
                        <XInputText form={this} field="modifXUser.name" label="Modified by" labelStyle={labelStyle} inputStyle={{width:'12.5rem'}}/>
                        <XInputText form={this} field="label" label="Label" labelStyle={labelStyle} inputStyle={{width:'20rem'}}/>
                        <XCheckbox form={this} field="useLabelAndLess" label="Use label 'and less'" labelStyle={labelStyle}/>
                        <XCheckbox form={this} field="useLabelAndMore" label="Use label 'and more'" labelStyle={labelStyle}/>
                        <XInputDecimal form={this} field="valueForUnknownValue" label="Value for unknown value" labelStyle={labelStyle}/>
                        {/* table is sorted by valueFrom in method this.preInitForm, default sort by id is turned off (sortField="none") */}
                        <XFormDataTable2 form={this} assocField="xsIntervalItemList" label="Interval items" sortField="none">
                            <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                            <XFormColumn field="valueFrom" header="Value from"/>
                            <XFormColumn field="valueTo" header="Value to"/>
                        </XFormDataTable2>
                    </div>
                </div>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
