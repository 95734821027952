import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XCalendar} from "@michalrakus/x-react-web-lib/XCalendar";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {XrReportWhereItem} from "../../model/reports/xr-report-where-item.entity";
import {XGroupByType, XrWhereItemType} from "../../common/enums";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XsGroupByField} from "../../model/statistics/xs-group-by-field.entity";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XEnum} from "../../model/user/x-enum.entity";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {InputText} from "primereact/inputtext";
import {XrRunReportRequest} from "../../common/utils-api";
import {XrReport} from "../../model/reports/xr-report.entity";
import {XFieldPropValuesForEdit} from "../zapisy/XFieldSetBase";

export interface XRunReportDialogState {
    opened: boolean;
    xrReportId?: number;
    onHideDialog?: () => void;
}

export const XRunReportDialog = (props: {
    dialogState: XRunReportDialogState;
}) => {

    const [xrReport, setXrReport] = useState<XrReport | undefined>(undefined);
    const [whereItemValueList, setWhereItemValueList] = useState<any[]>([]);
    const [whereItemErrorList, setWhereItemErrorList] = useState<(string | undefined)[]>([]); // used (mainly) for autocomplete error

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = async () => {

        const xrReportList: XrReport[] = await XUtils.fetchRows(
            'XrReport',
            {where: "[id] = :xrReportId", params: {xrReportId: props.dialogState.xrReportId}},
            "xrReportWhereItemList.itemOrder",
            ["xrReportWhereItemList.itemType.code", "xrReportWhereItemList.xsGroupByField.fieldType.code", "xrReportWhereItemList.xsGroupByField.xEnumEnum.id"]);
        if (xrReportList.length !== 1) {
            throw `Unexpected error - row XrReport with id = ${props.dialogState.xrReportId} not found.`;
        }
        const xrReport: XrReport = xrReportList[0];

        setXrReport(xrReport);
        const whereItemListLength: number = xrReport.xrReportWhereItemList.length;
        setWhereItemValueList(new Array(whereItemListLength)); // array with the same length as xrReportWhereItemList
        setWhereItemErrorList(new Array(whereItemListLength)); // array with the same length as xrReportWhereItemList
    }

    const onOk = () => {
        // validation
        for (const whereItemError of whereItemErrorList) {
            // only simple
            if (whereItemError) {
                alert(whereItemError);
                return;
            }
        }

        const xrRunReportRequest: XrRunReportRequest = {xrReportId: xrReport!.id, whereItemValueList: whereItemValueList};
        // zbavime label diakritiky a medzier, nech ma nazov suboru pekny format
        const fileName: string = XUtils.normalizeString(xrReport!.label).trim().replaceAll(' ', '-');
        XUtils.openExcelReport('xr-run-report', xrRunReportRequest, fileName);

        props.dialogState.onHideDialog!();
    }

    const createWhereItemElem = (index: number, xrReportWhereItem: XrReportWhereItem): JSX.Element => {
        const labelWidth: string = '12rem';
        let whereItemElem: JSX.Element;
        if (xrReportWhereItem.itemType.code === XrWhereItemType.groupByField) {
            const xsGroupByField: XsGroupByField | null = xrReportWhereItem.xsGroupByField;
            if (!xsGroupByField) {
                throw `Where item (order ${xrReportWhereItem.itemOrder}) with item type "${xrReportWhereItem.itemType.code}" - field "Group by field" is required.`;
            }
            const label: string = xrReportWhereItem.label ?? xsGroupByField.label;

            let entity: string;
            let displayField: string;
            let sortField: string;
            let filter: XCustomFilter | undefined = undefined;
            const fieldType: XEnum = xsGroupByField.fieldType;
            if (fieldType.code === XGroupByType.entityEnum) {
                if (!xsGroupByField.entity) {
                    throw `Group by field "${xsGroupByField.label}" - field "Entity" is required.`;
                }
                entity = xsGroupByField.entity;
                if (!xsGroupByField.displayField) {
                    throw `Group by field "${xsGroupByField.label}" - field "Display field" is required.`;
                }
                displayField = xsGroupByField.displayField;
                sortField = xsGroupByField.displayField;
            }
            else if (fieldType.code === XGroupByType.xEnum) {
                entity = "XEnum";
                displayField = "name";
                if (!xsGroupByField.xEnumEnum) {
                    throw `Group by field "${xsGroupByField.label}" - field "XEnumEnum" is required.`;
                }
                filter = {where: `[xEnumEnum] = ${xsGroupByField.xEnumEnum.id}`, params: {}};
                sortField = "enumOrder";
            }
            else {
                throw `Where item (order ${xrReportWhereItem.itemOrder}) with item type "${xrReportWhereItem.itemType.code}" - field "Group by field" has unsupported type ${fieldType.code}.`;
            }

            // add filter from group by field, if available
            filter = XUtilsCommon.filterAnd(filter, XUtilsCommon.createCustomFilter(xsGroupByField.filter));

            // TODO - idField="id" is hack, should be taken from Entity
            // we use lazy load, because we want to make sure, that autocomplete will work for every entity
            whereItemElem =
                <div className="field grid">
                    <label className="col-fixed" style={{width: labelWidth}}>{label}</label>
                    <XAutoCompleteBase value={whereItemValueList[index] ?? null}
                                       onChange={(object: any | null, objectChange: OperationType) => {
                                           const whereItemValueListCopy: any[] = [...whereItemValueList]; // create copy of list for hook setWhereItemValueList (otherwise the change will not be recognised)
                                           whereItemValueListCopy[index] = object; // write the change into the list
                                           setWhereItemValueList(whereItemValueListCopy);
                                       }}
                                       error={whereItemErrorList[index]} onErrorChange={(error: (string | undefined)) => whereItemErrorList[index] = error}
                                       idField="id"
                                       field={displayField}
                                       suggestionsQuery={{entity: entity, filter: filter, sortField: sortField, fields: [displayField]}}
                                       suggestionsLoad="lazy" lazyLoadMaxRows={15} minLength={1}/>
                </div>;
        }
        else if (xrReportWhereItem.itemType.code === XrWhereItemType.dateFromTo) {
            // we save dateFrom/dateTo into object of type {dateFrom: <dateFrom>, dateTo: <dateTo>}
            let dateFrom: Date | null = null;
            let dateTo: Date | null = null;
            const whereItemValue: any = whereItemValueList[index];
            if (whereItemValue) {
                dateFrom = whereItemValue.dateFrom;
                dateTo = whereItemValue.dateTo;
            }
            const onChangeDateFrom = (value: (Date | null)): void => {
                const whereItemValueListCopy: any[] = [...whereItemValueList]; // create copy of list for hook setWhereItemValueList (otherwise the change will not be recognised)
                const whereItemValue: any = whereItemValueListCopy[index];
                whereItemValueListCopy[index] = {dateFrom: value, dateTo: whereItemValue ? whereItemValue.dateTo : null}; // write the change into the list
                setWhereItemValueList(whereItemValueListCopy);
            }
            const onChangeDateTo = (value: (Date | null)): void => {
                const whereItemValueListCopy: any[] = [...whereItemValueList]; // create copy of list for hook setWhereItemValueList (otherwise the change will not be recognised)
                const whereItemValue: any = whereItemValueListCopy[index];
                whereItemValueListCopy[index] = {dateFrom: whereItemValue ? whereItemValue.dateFrom : null, dateTo: value}; // write the change into the list
                setWhereItemValueList(whereItemValueListCopy);
            }

            if (xrReportWhereItem.label) {
                // specific label - we put both dates into one line
                whereItemElem =
                    <div className="field grid">
                        <label className="col-fixed" style={{width: labelWidth}}>{xrReportWhereItem.label}</label>
                        <XCalendar value={dateFrom} onChange={onChangeDateFrom}/>
                        <label className="col-fixed" style={{width: '1rem'}}> - </label>
                        <XCalendar value={dateTo} onChange={onChangeDateTo}/>
                    </div>;
            }
            else {
                // no specific label, we use default
                whereItemElem =
                    <div>
                        <div className="field grid">
                            <label className="col-fixed" style={{width: labelWidth}}>{xLocaleOption('dateFrom')}</label>
                            <XCalendar value={dateFrom} onChange={onChangeDateFrom}/>
                        </div>
                        <div className="field grid">
                            <label className="col-fixed" style={{width: labelWidth}}>{xLocaleOption('dateTo')}</label>
                            <XCalendar value={dateTo} onChange={onChangeDateTo}/>
                        </div>
                    </div>;
            }
        }
        else if (xrReportWhereItem.itemType.code === XrWhereItemType.filter) {
            // read only where item
            whereItemElem =
                <div className="field grid">
                    <label className="col-fixed" style={{width: labelWidth}}>{xrReportWhereItem.label}</label>
                    <InputText value={xrReportWhereItem.whereItem} readOnly={true}/>
                </div>;
        }
        else {
            throw `Where item (order ${xrReportWhereItem.itemOrder}) - unknown item type "${xrReportWhereItem.itemType.code}".`;
        }
        return whereItemElem;
    }

    let whereItemElemList: JSX.Element[] = [];
    if (props.dialogState.opened && xrReport) {
        for (const [index, xrReportWhereItem] of xrReport.xrReportWhereItemList.entries()) {
            whereItemElemList.push(createWhereItemElem(index, xrReportWhereItem));
        }
    }

    // poznamka: renderovanie vnutornych komponentov Dialogu sa zavola az po otvoreni dialogu
    return (
        <Dialog visible={props.dialogState.opened} onShow={onShow} onHide={props.dialogState.onHideDialog!}>
            {whereItemElemList}
            <div className="flex justify-content-center">
                <XButton label={xLocaleOption('ok')} onClick={onOk}/>
            </div>
        </Dialog>
    );
}
