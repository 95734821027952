import React from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {XsIntervalSetForm} from "./XsIntervalSetForm";
import {Utils, XsAccessType} from "../../Utils";

export const XsIntervalSetBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const onAddRow = () => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsIntervalSetForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<XsIntervalSetForm id={selectedRow.id}/>);
    }

    const canAddRemoveRow = Utils.xsUserHasAccess(XsAccessType.full);

    return (
        <XLazyDataTable entity="XsIntervalSet" label="Interval sets" sortField="id desc" rows={30}
                        onAddRow={canAddRemoveRow ? onAddRow : undefined} onEdit={onEdit} removeRow={canAddRemoveRow}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="label" header="Label" width="25rem"/>
        </XLazyDataTable>
    );
}
